@import '../_utilidades/_utilidades';
table {
  font-size: 14px;
  border-bottom: none !important;
  // padding: 15px;
  width: 100%;
  tr {
    border-bottom: none !important;
    display: flex;
    justify-content: space-between;
    td,
    th {
      border-bottom: 0.0625rem solid #eee;
      padding: 12px 10px !important;
      flex-grow: 1;
      flex-basis: 0;
    }
    .checkbox {
      flex: 0;
      width: 80px;
    }
    .checkboxInput {
      flex-grow: 0;
    }
    &:hover {
      background: $whiteLightColor4;
    }
  }
  thead {
    th {
      font-weight: 700;
    }
  }
}

.itemsPerView {
  li {
    margin: 10px 0;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 6px;
    border: 1px solid #ccc;
    .customItemsPerView {
      background: transparent;
      border: none;
    }
  }
}

.pagination {
  border-bottom: none;
  li {
    margin: 10px 4px;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 6px;
    .customPaginator {
      font-size: 11px;
      margin-top: -8px;
    }
  }

  .disabled {
    color: #bbb !important;
    background: transparent !important;
    border: none !important;
  }
  &:first-child {
    order: 2;
  }
  &:last-child {
    li {
      // border: 1px solid #ccc;
      margin: 10px 0;
      padding: 6px 10px;
    }
  }
}

.edit {
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 600;
  .icon {
    fill: $cor-azulClaro;
    height: 16px;
    margin-left: 10px;
    width: 16px;
  }
}

mfbootstrappaginator {
  width: 100%;
  mfpaginator {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;

    nav {
      &.pull-right {
        display: none;
      }
      li {
        cursor: pointer;
        &:last-child {
          a {
            display: none;
          }
          background: transparent;
          border: 2px solid $sucesso;
          color: $sucesso;
          border-radius: 3px;
          padding: 4px;
          &:hover {
            background: $sucesso;
            color: #fff;
          }

          &::before {
            content: 'Próxima ';
          }
          &:disabled {
            opacity: 0.65 !important;
            cursor: default !important;
          }
        }
        &:first-child {
          a {
            display: none;
          }
          background: transparent;
          border: 2px solid $sucesso;
          color: $sucesso;
          border-radius: 3px;
          padding: 4px;
          &:hover {
            background: $sucesso;
            color: #fff;
          }
          &::after {
            content: ' Anterior';
          }
          &:disabled {
            opacity: 0.65;
            cursor: default;
          }
        }
      }
    }
  }
}
