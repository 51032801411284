@import '../_utilidades/_utilidades';

.containerPrincipal {
  display: inline-block;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  &_grid {
    padding-top: pxToRem(130);
    position: relative;

    &_conteudo {
      display: inline-block;
      @include flexItens(null, null, 1 1 auto);
      //altura minima deve ser feita desse modo
      //para quando não houver conteúdo suficiente não criar scroll vertical
      @include calc(min-height, '100vh - #{pxToRem(160)}', null);
      overflow: hidden;
      // padding: 0 pxToRem(10);
      @include calc(width, '100% - #{pxToRem(270)}', null);
    }
  }

  @media(min-width: pxToRem(992)) {
    &_grid {
      &_conteudo {
        padding: 0 pxToRem(15);
      }
    }
  }
}

.container {
  &--topo {
    border-bottom: solid pxToRem(1);
    padding: 0;
  }
}

.boxConteudo {
  width: 100%;

  // @media(min-width: pxToRem(1280))  {
  //   padding: pxToRem(20)!important;
  // }
}
