//@import '../../../../node_modules/bootstrap/scss/_variables';
@import '_funcoes';

//Váriavel de font-base
$fontBase: 16 !default;

$theme-colors: (
  'success': #3c8dbc,
  'danger': #dc3545,
  'warning': #ffc107,
);

$custom-file-text: (
  placeholder: (
    pt-br: 'Selecionar arquivo...',
    pt: 'Selecionar arquivo...',
  ),
  button-label: (
    pt-br: 'Navegar',
    pt: 'Navegar',
  ),
);

$logoWidth: 4rem;
$logoHeight: 2rem;

//$sucesso: #09b062;
$sucesso: #005baa;
$cor-azulClaro: #3c8dbc;
$cor-verde: #0097d7;
$cor-verdeClaro: #57a9f1;
$cor-verdeTransparent: #bed7cb;
$cor-branca: #fff;
$cor-preta: #000;
$cor-cinza: #ccc;
$cor-cinzaClaro: #f0f0f0;
$cor-danger: #dc3545;
$cor-warning: #f39c12;

$buttonPrimary: #0097d7;
$buttonSecondary: #ababab;
$buttonSuccess: #005baa;
$buttonDanger: #dc3545;
$transparent: transparent;

$redColor: #e20613;
$orange: #ff5400;
$darkSilver: #292b2c;
$darkBlueColor2: #048dc1;
$whiteColor: #fff;
$whiteLightColor: #f0f0f0;
$whiteLightColor1: #f1f1f1;
$whiteLightColor2: #f9f9f9;
$whiteLightColor4: #eff5f9;
$gray: #ccc;
$gray-light: #ccc;
$lightGray: #dadada;
$lightGray2: #eee;
$lightGray3: #ddd;
$darkGray: #706f6f;
$darkGray1: #b9b9b9;
$blackColor: #000;
$darkBlueColor: #2e4f8f;
$darkBlue2: #004f9f;
$lightBlueColor: #57a9f1;
$lightBlueColor1: #a2d2f3;
$lightBlueColor2: #3c8dbc;
$blueLightColor: #3ec0e8;
$darkGreenColor: #004f9f;
$darkGreenColor2: #004f9f;
$colorBorderForm: #d2d6de;
$boxShadow: #8f8f8f;
$bordaSessaoFormularios: #ddd;
$placeholderInputFile: #868e96;
$iconeHeader: #737373;
$headerEixos: #fbfbfb;
$backgroudItemNotificacao: #f9f9f9;
$colorError: #dc3545;
$headerColor: #fff;
$headerBorderColor: transparent;
$rodapeBorderColor: transparent;
$headerFontColor: #004f9f;
$conteudoTopPage: 10;
$sideBarTopPage: 52;
$breadCrumbUserTop: 30px;