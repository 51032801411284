@import '../_utilidades/_utilidades';

//logo da GISS no header
.logo {
  height: pxToRem(32);
  width: pxToRem(64);

  &_icon {}

  &_text {}
}

.icone {
  display: inline-block;
  fill: inherit;
  transition: fill .15s ease-in-out;
  will-change: fill;

  &--notificacao {
    fill: $cor-azulClaro;
  }

  &--header {
    fill: $cor-branca;
    height: pxToRem(24);
    width: pxToRem(24);
    vertical-align: middle;
  }

  // tamanhos
  &--md {
    height: pxToRem(16);
    width: pxToRem(16);
  }

  &--lg {
    height: pxToRem(24);
    width: pxToRem(24);
  }

  &--primary {
    fill: $cor-azulClaro;
  }
}
