/*
   Estilos globais para todas as aplicações são adicionados
   em seus respectivos arquivos que serão importados aqui
*/

//bootstrap vem em primeiro lugar pois nossos estilos devem ter
//prioridade na espeficidade do CSS
// @import '../node_modules/bootstrap/scss/bootstrap';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~font-awesome/css/font-awesome.css';

//Estilos base/resets
@import 'assets/css/_normalize/_normalize';

//Animate CSS
@import '~animate.css/animate.min';

//Estilos para hacks de plugins ou sujeiras necessárias no CSS
@import 'assets/css/_hacks/_falbacks';

//Estilo para icones
@import 'assets/css/_modulos/_icones';

//summernote
@import '~summernote/dist/summernote.css';

//ngx table

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@import 'estrutura';

//Estilos de layout
@import 'assets/css/_modulos/_container';

//Estilos para módulos
@import 'assets/css/_modulos/_boxes';
@import 'assets/css/_modulos/_botoes';
@import 'assets/css/_modulos/_dropdowns';
@import 'assets/css/_modulos/_forms';
@import 'assets/css/_modulos/_formatacao';
@import 'assets/css/_modulos/_images';
@import 'assets/css/_modulos/_listas';
@import 'assets/css/_modulos/_tabelas';
@import 'assets/css/_modulos/_icones';
@import 'assets/css/_modulos/_datepicker';
@import 'assets/css/_modulos/sweetalert';

// smooth scroll
html {
   //scroll-behavior: smooth;
}

li {
  list-style-type: none;
}

.table-responsive {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: auto;
  // padding: 15px;
  .row {
    margin: 10px auto;
  }
  &_actions {
    margin: pxToRem(30) 0 pxToRem(20) 0;
  }
}

dp-date-picker {
  display: block !important;
}

//configuracao sweetalert
.swalAlertEstilizado {
  &.swal2-popup {
    display: initial !important;
  }

  .swal2-icon-text {
    font-size: 2em !important;
  }

  .swal2-header {
    display: initial !important;
    float: left !important;
    width: 90px;
    height: 72px;
  }

  .swal2-icon {
    width: 3em !important;
    height: 3em !important;
    line-height: 3em !important;
  }

  .swal2-content {
    padding-top: 16px !important;
    width: 460px !important;
    #swal2-content {
      text-align: justify !important;
      margin-left: 100px;
    }
  }

  ol {
    padding-left: 14px;
  }

  .swal2-actions {
    display: block !important;
      text-align: center !important;
      button {
        margin-top: 15px !important;
      }
  }
}

// configuracao footer
.footer-area {
  background-color: #8d8d8d;
  padding: 25px 0px 15px;
}

// .margem1 {
//   margin-top: 80px;
// }

.fonte-rodape{
  color: #fff;
  margin: auto;
  font-size: 15px;
}


.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center;
  background: rgba(22,22,22,0.5);

  svg {
    margin-top: 22%;
  }

  img {
    max-height: 15%;
    max-width: 15%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
