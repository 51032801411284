
  .dp-calendar-nav-container {
    padding: 20px;
    border: none!important;
    font-weight: 600;
    color: #000;
    
  }
  .dp-calendar-wrapper {
    padding: 10px;
    border: none!important;
    .dp-weekdays,
    .dp-months-row,
     .dp-calendar-week {
      font-size: 12px;
      font-weight: 500;
    }
    .dp-calendar-week {
    .dp-current-day {
        border: 1px solid $cor-cinza;
        color: #000;
        // font-weight: 600;
      }
    }
  }


.dp-nav-btns-container {
  .dp-calendar-nav .dp-calendar-nav-left::before {
    border-color: #ccc!important;
  }
}

.dp-current-location-btn {
  display: none;
}

// .dp-calendar-week {
//   .dp-current-day {
//       border: 2px solid #4d90fe;
//       color: #4d90fe;
//       font-weight: 600;
//     }
//   }