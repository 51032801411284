@import '../_utilidades/_utilidades';

.texto--sm {
    font-size: .875rem;
}

.form--btnSearch-active{
    fill:  $sucesso !important;
    -webkit-animation-name: onShow;
    animation-name: onShow;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes onShow {
    from {opacity: 0;}  
    to {opacity: 1}
}
@keyframes onShow {
    from {opacity: 0;}  
    to {opacity: 1}
}