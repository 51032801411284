@import '../_utilidades/_utilidades';

.titulosBox {
  margin-bottom: pxToRem(20);

  &_titulo,
  &_subTitulo {
    line-height: pxToRem(16);
    margin-bottom: pxToRem(12);
  }

  &_titulo {
    font-size: pxToRem(20);
    font-weight: 600;
  }

  &_subTitulo {
    font-size: pxToRem(14);
    font-weight: 500;
  }

  @media(min-width: pxToRem(992)) {
    &_titulo {
      font-size: pxToRem(24);
    }

    &_subTitulo {
      font-size: pxToRem(16);
    }
  }
}
