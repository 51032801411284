@import '../_utilidades/_utilidades';
@import '../_utilidades/_variaveis';
.boxMensagens {
  &_aviso {
    padding: pxToRem(12);
  }
  &_item {
    background-color: $whiteLightColor2;
    border-top: pxToRem(1) solid $cor-azulClaro;
    cursor: pointer;
    padding: pxToRem(8) pxToRem(4) pxToRem(8) pxToRem(16);
    transition: background-color .12s ease-in-out;
    &_icone {
      background: $whiteLightColor2;
      border: pxToRem(1) solid rgba($cor-preta, .4);
      color: $cor-cinza;
      height: pxToRem(36);
      padding: pxToRem(8);
      width: pxToRem(36);
    }
    &:hover {
      background-color: rgba($cor-cinza, .4);
      text-decoration: none;
    }
  }
}

.boxContainer {
  background-color: #fff;
  // border-top: 1px solid #e1e1e1;
  // box-shadow: 0 pxToRem(1) pxToRem(5) #ccc;
  margin: auto;
  position: relative;
  // padding: pxToRem(25) 0;
  &_conteudo {}
}

.boxConteudo {
  width: 100%;
  @include calc(min-height, '100% - #{pxToRem(64)}', null);
  min-height: 70vh;
  padding: 0 2.25rem;
  &_title {
    margin-top: 1.5rem !important;
    h1 {
      font-size: 16px;
      font-weight: 800;
      color: hsl(0, 0%, 20%);
    }
    &_update {
      height: pxToRem(20);
      width: pxToRem(20);
      fill: #aaa;
      cursor: pointer;
      &:hover {
        fill: $darkBlueColor;
      }
    }
  }
  &_tab {
    margin: 0;
    padding: 0;
    width: 95%;
    &_item {
      background: transparent;
      display: inline-block;
      padding: 15px 25px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #bbb;
      cursor: pointer;
      &_icon {
        width: pxToRem(16);
        height: pxToRem(16);
        vertical-align: middle;
        margin: 0;
        fill: #bbb;
        margin-right: pxToRem(4);
      }
      &.active,
      &:hover {
        background: transparent;
        color: $cor-azulClaro;
        border: 1px solid $cor-azulClaro;
        border-bottom: 0;
        border-top: 3px solid $cor-azulClaro;
        .boxConteudo_tab_item_icon {
          fill: $darkBlue2;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  // .boxcConteudo {
  //   padding: pxToRem(20)!important;
  // }
  .boxConteudo_tab_item {
    font-size: 0;
  }
  .boxConteudo_tab_item span svg {
    margin: 0;
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  label {
    padding: 15px;
  }
}

// .boxConteudo {
//   position: relative;
//   border-radius: 3px;
//   background: #ffffff;
//   margin-bottom: 20px;
//   border-radius: 5px;
//   border: none;
//   -webkit-box-shadow: 0 1px 5px #ddd;
//   box-shadow: 0 1px 5px #ddd;
//   padding: 8px;
//   width: 90%;
//   margin: auto;
// }
