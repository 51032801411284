@import '../_utilidades/_utilidades';

router-outlet {
  &:empty {
    display: none;
  }

  + * {
    &:empty {
      display: none;
    }
  }
}

.dataTables_wrapper {
  .dataTables_paginate {
    .paginate_button {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-outline-secondary !optional;
    }
  }

  .dataTables_filter {
    margin-right: pxToRem(8);

    input {
      @extend .form-control;
      @extend .form-control-sm !optional;
    }
  }
}

dp-day-calendar.dp-material .dp-selected {
  background: $cor-azulClaro !important;
  color: #fff;
  &:hover {
    background: $cor-azulClaro !important;
  }
}

.border .border-success {
  border: 1px solid $cor-azulClaro;
}

.form-control {
  background: #e9ecef !important;
  &:disabled {
    background: #fff;
  }
  &:read-only {
    background: #fff;
  }
}

textarea {
  background: #e9ecef !important;
  &:disabled {
    background: #fff !important;
  }
  &:read-only {
    background: #fff !important;
  }
}

select,
option {
  background: #e9ecef !important;
  &:disabled {
    background: #fff !important;
  }
  &:read-only {
    background: #e9ecef !important;
  }
}
