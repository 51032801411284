@import '../_utilidades/utilidades';

//correcao do posicionamento do menu dropdown dos itens do header quando a pagina é extensa.
.dropdown--hack {
  transform: translate3d(pxToRem(-220), pxToRem(50), pxToRem(0)) !important;
  top: pxToRem(0) !important;
  left: pxToRem(0) !important;
  min-width: pxToRem(280) !important;
}

//hack para dropdown de usuario
.menuUsuario {
  .dropdown {
    &--hack {
      transform: translate3d(pxToRem(-136), pxToRem(50), pxToRem(0)) !important;
    }
  }
}
